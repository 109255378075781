import React from 'react';
import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews';
import { PageProps } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/controls/SEO';

type PreviewPageProps = PageProps;

const Preview = ({ location }: PreviewPageProps) => (
  <Layout location={location}>
    <SEO
      key="page-seo-item"
      title="Preview Page"
      description="CMS Preview Page"
      meta={[
        {
          name: 'robots',
          content: 'noindex',
        },
      ]}
    />

    <div className="container neo-text-content">
      <h1>Loading Preview</h1>
      <p>Please wait while the preview is loading...</p>
    </div>
  </Layout>
);

export default withPrismicPreviewResolver(Preview);
